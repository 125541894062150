<template>
    <!-- Services-->
  <section class="page-section" id="services">
    <div class="">
      <div class="text-center">
        <div id="" class="contact">
            <h2>ご予約・見学受付</h2>
            <p>※事前に必ず見学をお願いします【平日10時から11時頃でお願いしています】</p>
            <form class="contact-form" method="post" action="">
                <table>
                    <tbody>
                        <tr>
                            <th>お名前</th>
                            <td>
                                <input class="form-control" v-model="name" placeholder="例）田中 太郎">
                                <p class="error">{{ errors.name }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>フリガナ</th>
                            <td>
                                <input class="form-control" v-model="kana" placeholder="例）タナカ タロウ">
                                <p class="error">{{ errors.kana }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>
                                <input class="form-control" v-model="tel" placeholder="例）09012341234">
                                <p class="error">{{ errors.tel }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                <input class="form-control" v-model="email" placeholder="例）info@kaon-r.jp">
                                <p class="error">{{ errors.email }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>希望日時</th>
                            <td>
                                第一希望
                                <input class="form-control" type="date" v-model="date_1" />
                                <select class="form-control" v-model="date_1_time">
                                    <!--
                                    <option>0:00〜</option>
                                    <option>0:15〜</option>
                                    <option>0:30〜</option>
                                    <option>0:45〜</option>
                                    <option>1:00〜</option>
                                    <option>1:15〜</option>
                                    <option>1:30〜</option>
                                    <option>1:45〜</option>
                                    <option>2:00〜</option>
                                    <option>2:15〜</option>
                                    <option>2:30〜</option>
                                    <option>2:45〜</option>
                                    <option>3:00〜</option>
                                    <option>3:15〜</option>
                                    <option>3:30〜</option>
                                    <option>3:45〜</option>
                                    <option>4:00〜</option>
                                    <option>4:15〜</option>
                                    <option>4:30〜</option>
                                    <option>4:45〜</option>
                                    <option>5:00〜</option>
                                    <option>5:15〜</option>
                                    <option>5:30〜</option>
                                    <option>5:45〜</option>
                                    <option>6:00〜</option>
                                    <option>6:15〜</option>
                                    <option>6:30〜</option>
                                    <option>6:45〜</option>
                                    <option>7:00〜</option>
                                    <option>7:15〜</option>
                                    <option>7:30〜</option>
                                    <option>7:45〜</option>
                                    <option>8:00〜</option>
                                    <option>8:15〜</option>
                                    <option>8:30〜</option>
                                    <option>8:45〜</option>
                                    -->
                                    <option selected>9:00〜</option>
                                    <option>9:15〜</option>
                                    <option>9:30〜</option>
                                    <option>9:45〜</option>
                                    <option>10:00〜</option>
                                    <option>10:15〜</option>
                                    <option>10:30〜</option>
                                    <option>10:45〜</option>
                                    <option>11:00〜</option>
                                    <option>11:15〜</option>
                                    <option>11:30〜</option>
                                    <option>11:45〜</option>
                                    <option>12:00〜</option>
                                    <option>12:15〜</option>
                                    <option>12:30〜</option>
                                    <option>12:45〜</option>
                                    <option>13:00〜</option>
                                    <option>13:15〜</option>
                                    <option>13:30〜</option>
                                    <option>13:45〜</option>
                                    <option>14:00〜</option>
                                    <option>14:15〜</option>
                                    <option>14:30〜</option>
                                    <option>14:45〜</option>
                                    <option>15:00〜</option>
                                    <option>15:15〜</option>
                                    <option>15:30〜</option>
                                    <option>15:45〜</option>
                                    <option>16:00〜</option>
                                    <option>16:15〜</option>
                                    <option>16:30〜</option>
                                    <option>16:45〜</option>
                                    <option>17:00〜</option>
                                    <option>17:15〜</option>
                                    <option>17:30〜</option>
                                    <option>17:45〜</option>
                                    <option>18:00〜</option>
                                    <option>18:15〜</option>
                                    <option>18:30〜</option>
                                    <option>18:45〜</option>
                                    <option>19:00〜</option>
                                    <option>19:15〜</option>
                                    <option>19:30〜</option>
                                    <option>19:45〜</option>
                                    <option>20:00〜</option>
                                    <option>20:15〜</option>
                                    <option>20:30〜</option>
                                    <option>20:45〜</option>
                                    <option>21:00〜</option>
                                    <!--
                                    <option>21:15〜</option>
                                    <option>21:30〜</option>
                                    <option>21:45〜</option>
                                    <option>22:00〜</option>
                                    <option>22:15〜</option>
                                    <option>22:30〜</option>
                                    <option>22:45〜</option>
                                    <option>23:00〜</option>
                                    <option>23:15〜</option>
                                    <option>23:30〜</option>
                                    <option>23:45〜</option>
                                    -->
                                </select>
                                第二希望
                                <input class="form-control" type="date" v-model="date_2" />
                                <select class="form-control" v-model="date_2_time">
                                    <!--
                                    <option>0:00〜</option>
                                    <option>0:15〜</option>
                                    <option>0:30〜</option>
                                    <option>0:45〜</option>
                                    <option>1:00〜</option>
                                    <option>1:15〜</option>
                                    <option>1:30〜</option>
                                    <option>1:45〜</option>
                                    <option>2:00〜</option>
                                    <option>2:15〜</option>
                                    <option>2:30〜</option>
                                    <option>2:45〜</option>
                                    <option>3:00〜</option>
                                    <option>3:15〜</option>
                                    <option>3:30〜</option>
                                    <option>3:45〜</option>
                                    <option>4:00〜</option>
                                    <option>4:15〜</option>
                                    <option>4:30〜</option>
                                    <option>4:45〜</option>
                                    <option>5:00〜</option>
                                    <option>5:15〜</option>
                                    <option>5:30〜</option>
                                    <option>5:45〜</option>
                                    <option>6:00〜</option>
                                    <option>6:15〜</option>
                                    <option>6:30〜</option>
                                    <option>6:45〜</option>
                                    <option>7:00〜</option>
                                    <option>7:15〜</option>
                                    <option>7:30〜</option>
                                    <option>7:45〜</option>
                                    <option>8:00〜</option>
                                    <option>8:15〜</option>
                                    <option>8:30〜</option>
                                    <option>8:45〜</option>
                                    -->
                                    <option selected>9:00〜</option>
                                    <option>9:15〜</option>
                                    <option>9:30〜</option>
                                    <option>9:45〜</option>
                                    <option>10:00〜</option>
                                    <option>10:15〜</option>
                                    <option>10:30〜</option>
                                    <option>10:45〜</option>
                                    <option>11:00〜</option>
                                    <option>11:15〜</option>
                                    <option>11:30〜</option>
                                    <option>11:45〜</option>
                                    <option>12:00〜</option>
                                    <option>12:15〜</option>
                                    <option>12:30〜</option>
                                    <option>12:45〜</option>
                                    <option>13:00〜</option>
                                    <option>13:15〜</option>
                                    <option>13:30〜</option>
                                    <option>13:45〜</option>
                                    <option>14:00〜</option>
                                    <option>14:15〜</option>
                                    <option>14:30〜</option>
                                    <option>14:45〜</option>
                                    <option>15:00〜</option>
                                    <option>15:15〜</option>
                                    <option>15:30〜</option>
                                    <option>15:45〜</option>
                                    <option>16:00〜</option>
                                    <option>16:15〜</option>
                                    <option>16:30〜</option>
                                    <option>16:45〜</option>
                                    <option>17:00〜</option>
                                    <option>17:15〜</option>
                                    <option>17:30〜</option>
                                    <option>17:45〜</option>
                                    <option>18:00〜</option>
                                    <option>18:15〜</option>
                                    <option>18:30〜</option>
                                    <option>18:45〜</option>
                                    <option>19:00〜</option>
                                    <option>19:15〜</option>
                                    <option>19:30〜</option>
                                    <option>19:45〜</option>
                                    <option>20:00〜</option>
                                    <option>20:15〜</option>
                                    <option>20:30〜</option>
                                    <option>20:45〜</option>
                                    <option>21:00〜</option>
                                    <!--
                                    <option>21:15〜</option>
                                    <option>21:30〜</option>
                                    <option>21:45〜</option>
                                    <option>22:00〜</option>
                                    <option>22:15〜</option>
                                    <option>22:30〜</option>
                                    <option>22:45〜</option>
                                    <option>23:00〜</option>
                                    <option>23:15〜</option>
                                    <option>23:30〜</option>
                                    <option>23:45〜</option>
                                    -->
                                </select>
                                <p class="error">{{ errors.date }}</p>
                                
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>
                                <div class="alert alert-warning" role="alert">希望日時は下記カレンダーより空いている時間帯をご指定ください。</div>
                                <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTokyo&mode=WEEK&showTitle=0&showPrint=0&showTabs=1&showCalendars=1&showTz=0&showDate=1&src=dHJkcGYyNGZ2YTQ3cWI3dWdoYjc5cGs3NjRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23E4C441&color=%23AD1457" style="border-width:0" width="100%" height="600" frameborder="0" scrolling="no"></iframe></td>
                        </tr>
                        <tr>
                            <th>決済方法</th>
                            <td>
                                <select class="form-control" v-model="paymentmethod">
                                    <option selected>クレジット</option>
                                    <option>銀行振込</option>

                                </select>
                                <p class="error">{{ errors.paymentmethod }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>コメント</th>
                            <td>
                                <textarea name="" v-model="comment"></textarea>
                                <p class="error">{{ errors.comment }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <textarea class="form-control form-group" rows="20" name="privacy" id="privacy" placeholder="">
 ●料金について
当サロンに会員登録していただきますと以下の料金でご利用いただけます。

★キャンペーン中につき全日特価となります。
【全日】
15分/415円（税込）
1時間/1,660円（税込）

通常は下記の金額となります。
【平日】
15分/495円（税抜450円）
1時間/1,980円（税抜1,800円）

【土日祝日】
15分/550円（税抜500円）
1時間/2,200円（税抜2,000円）

※最低ご利用2時間からとなります。
※掃除時間も含めた時間でご予約下さい。
※ご予約時間からの入室となります。それより早いと前の方がご在室の場合がありますのでご注意下さい。


●当店は女性専用の会員制サロンです
まずはサロン見学をお願い致します。設備や備品についてご説明致します。その際ご利用目的・内容・経歴等をお聞きします。会員登録をご希望の場合は、申し込み書をお渡ししますのでご記入後メール・FAX・郵送にてご送付ください。
※平日10時から11時頃が見学ご案内をしやすいです。
※申し込み書が届いた後、審査をさせていただきます。1週間以内にメールまたはLINEにてご連絡させていただきます。

●キャンセルについて
はい、かかります。キャンセルするタイミングによってキャンセル料金は変わります。下記をご確認ください。
 • 8日より前:0%
 • 7日前〜4日前:25%
 • 3日前〜前日:50%
 • 当日:100%
※◯日前というのは23:59分を基準としております。例）1月1日予約の場合、12月31日23:59分までが前日キャンセル、それ以降は当日キャンセルとなります。それ意外のキャンセルに関しても同様となります。

●入退室時間について
入室は、前にご予約が入っていない場合は10分前から可能です。原則ご予約時間通りに入退室をお願いいたします（掃除も含めた時間をご予約下さい）前日に管理者よりご予約状況をお伝えします。
ご利用時間を超えてご利用になる場合は15分毎の延長料金を頂いております。
15分/415円
こちらを封筒をお使い頂き専用ボックスへ入れてください。
※入退室時間はセキュリティカメラにて記録されるようになっております。他のご利用者様の迷惑になるような場合はご利用を控えて頂く事がございます。

●賠償責任について
 • ご利用中の人的・物的損害等トラブルに関しては、全て申込者様に賠償請求をいたします。
 • 室内およびビル・マンションを破損・汚損等された場合、また、火災事故を起こした場合、全て申込者様に賠償請求をいたします。
 • ご利用後、特に清掃改修が必要な場合、またサロン内の設備や備品の破損においても、全て申込者様に別途費用を請求いたします。
 • また、上記により当サロンの営業に支障をきたした場合は、損害の実費負担と利用料金に基づいた逸失利益の合計金額を、全て申込者様に請求いたします。
 • 上記禁止行為に該当し、当サロン運営上に損害が生じたと判断した場合、賠償請求をいたします。

●免責事項について
 • ご利用中の出来事はすべて自己責任で行なってください。利用者様及びその関係者が当サロン内で起こした事故・損害・トラブル等に対して、弊社では一切責任を負いかねますので、あらかじめご了承ください。
 • 当サロンをご利用されたことに起因するあらゆる損害（直接的・間接的を問わず）に対して、弊社はいかなる責任も負いません。
 • 自然災害・暴動・政府規制等の不可抗力により利用ができない場合、また停電・設備の故障などのやむを得ない事情によりお部屋の貸出ができなくなった場合は、使用をお断りする場合がございます。その場合も弊社ではいかなる責任も負いません。
 • 貴重品やお荷物の紛失・盗難が起こっても、弊社はいかなる責任も負いません。必ず自己管理をお願いいたします。忘れ物をした場合はすぐに弊社にご連絡ください。弊社スタッフが見つけた場合は保管いたしますが、紛失・破損等があっても補償は致しません。(忘れ物の保管期限は14日間です)
                </textarea>

                <!-- Agreement -->
                <label class="form__group form__group--no-label form__group--checkbox">
                    <input
                    class="form__input" type="checkbox" name="form-agreement"
                    v-model="agreement"
                    >
                    <span class="form__label">上記規約の同意する</span>
                    <p class="error">{{ errors.agreement }}</p>
                </label>

                <button v-if="!valid" :disabled="!valid" type="button">未入力の必須項目があります</button>
                <button v-else-if="valid" type="button" @click="submit">送信</button>
            </form>
            <transition name="fade">
                <div v-if="result" class="contact-result">{{ result }}</div>
            </transition>
        </div>
    </div>
    </div>
    </section>
</template>


<script>
    import axios from 'axios'
    export default {
        data: function() {
            return {
                name: '',
                kana: '',
                tel: '',
                email: '',
                date_1: '',
                date_1_time: '',
                date_2: '',
                date_2_time: '',
                paymentmethod: '',
                comment: '',
                result: '',
                agreement: '',
            }
        },
        computed: {
            chackName: function(){
                if(!this.name){
                    return 'お名前を入力してください';
                }
                return '';
            },
            chackKana: function(){
                if(!this.kana){
                    return 'フリガナを入力してください';
                } else if(!this.validKatakana(this.kana)){
                    return 'フリガナをカタカナで入力してください';
                }
                return '';
            },
            checkTel: function(){
                if(!this.tel){
                    return '電話番号（ハイフン無し）を入力してください';
                } else if(!this.validTel(this.tel)){
                    return 'ハイフン無し電話番号を正しく入力してください';
                }
                return '';
            },
            chackEmail: function(){
                if(!this.email){
                    return 'メールアドレスを入力してください';
                } else if(!this.validEmail(this.email)){
                    return 'メールアドレスを正しく入力してください';
                }
                return '';
            },
            chackAgreement: function(){
                if(!this.agreement){
                    return '規約に同意ください';
                }
                return '';
            },
            errors: function() {
                const errors = {
                    'name': this.chackName,
                    'kana': this.chackKana,
                    'tel': this.checkTel,
                    'email': this.chackEmail,
                    'agreement': this.chackAgreement,
                };
                for (var key in errors) {
                    if (errors[key] === '' || errors[key] === null || errors[key] === undefined) {
                        delete errors[key];
                    }
                }
                return errors;
            },
            valid: function() {
                return !Object.keys(this.errors).length;
            }
        },
        methods: {
            submit: async function(){
                const result = await this.send();
                const url = "/thanks";
                this.result = result; //ページ移動
                if(result === '送信完了'){
                    console.log('OK');
                    location.href = url; //ページ移動
                    this.clear();
                }
            },
            send: async function(){
                const url = 'https://api2.kaon-r.jp/contact/send_reserve.php';
                const params = {
                    "お名前": this.name,
                    "フリガナ": this.kana,
                    "連絡先": this.tel,
                    "email": this.email,
                    "第一希望（日にち）": this.date_1,
                    "第一希望（時間）": this.date_1_time,
                    "第二希望（日にち）": this.date_2,
                    "第二希望（時間）": this.date_2_time,
                    "決済方法": this.paymentmethod,
                    "コメント": this.comment,
                }
                return await axios
                    .post(url, params)
                    .then(function(res){
                        return res.data;
                    })
                    .catch(function(error){
                        console.log(error);
                });
            },
            validKatakana: function(kana) {
                const re = /^[ァ-ンｧ-ﾝ\x20\u3000ﾞﾟ]*$/;
                return re.test(kana);
            },
            validTel: function(tel) {
                const re = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
                return re.test(tel);
            },
            validEmail: function(email) {
                const re = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
                return re.test(email);
            },
            // validAgreement: function(agreement) {
            //    const re = false;
            //    return re.test(agreement);
            //},
            clear: function(){
                this.name = '';
                this.kana = '';
                this.tel= '';
                this.email = '';
                this.date_1 = '';
                this.date_1_time = '';
                this.date_2 = '';
                this.date_2_time = '';
                this.paymentmethod = '';
                this.comment = '';
                this.agreement = '';
            }
        }
    }
    </script>




<style scoped>
    div, p, h2, ul, li {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    textarea {
        resize: vertical;
        width: 100%;
        min-height: 300px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
    textarea:focus-visible {
        color: #6a4229;
        background-color: #fff;
        border-color: #8c705e;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(145 97 0 / 28%);
    }
    .contact {
        width: 100%;
        max-width: 960px;
        padding: 28px;
        margin: 130px auto 0 auto;
    }
    .contact h2 {
        margin: 0 0 28px 0;
    }

    .contact-form table tbody tr td input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .contact-form .error {
        margin: 0;
        color: #ff0000;
        font-weight: bold;
        font-size: .85rem;
    }
    .contact-form button {
        display: block;
        width: 300px;
        padding: 10px 0;
        margin: 0 auto;
        color: #ffffff;
        border: 0;
        box-shadow: none;
        background-color: #384878;
        cursor: pointer;
    }
    .contact-form button:disabled {
        background-color: #a5a5a5;
    }
    .contact-result {
        padding: 5px 0;
        margin: 28px auto 0 auto;
        color: #ffffff;
        box-sizing: border-box;
        background-color: #00c2bc;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 14px;
        margin: 40px 0;
    }
    table th {
        width: 22%;
        background: #8c705e;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: middle;
        font-weight: bold;
        text-align: center;
        color: #fff;
        border: #fff 1px solid;
    }
    table td {
        background: #fff;
        box-sizing: border-box;
        padding: 15px;
        vertical-align: top;
        text-align: left;
        border: #fff 1px solid;
    }
    @media all and (max-width: 767px) {
        table th, table td {
            display: block;
            width: 100%;
            border-bottom: none;
        }
        table tr:last-child td:last-child {
            border-bottom: 1px solid #ccc;
        }
    }
    select {
        -moz-appearance: menulist;
        -webkit-appearance: menulist;
    }
    .alert-warning {
    padding: 10px !important;
    margin: 0 0 10px 0;
    font-weight: bold;
    }
    </style>